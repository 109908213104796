#root {
  width: 100%;
  height: 100%;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  position: relative;
}
