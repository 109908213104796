@import "../../styles/mixin";
@import "../../styles/var";

.custom-react-date-picker-container {
  @include flex-column;

  .custom-datepicker-box {
    @include flex-column;
    border-radius: 10px;
    background: $blue-shade-5;
    box-shadow: 0 0 10px $primary-color;
    padding: 4px 1rem 8px;
    margin: 1rem;

    .custom-react-date-picker-label {
      font-size: 12px;
    }

    .custom-react-date-picker-component {
      width: 100%;
      background: none;
      border: none;
      outline: none;
      color: $primary-color;
    }
  }
}

.input-transparent-blue-theme {
  .custom-datepicker-box {
    background: $blue-gradient-7 !important;
  }
}