@import "../../styles/mixin";
@import "../../styles/var";

.flip-card {
  background-color: transparent;
  width: 230px;
  height: 230px;
  perspective: 1000px;
  margin: 4rem;

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);


    .flip-card-front {
      @include flex-column;
      background: $blue-gradient-3;
      border: 3px solid $gray-shade-1;
      border-radius: 14px;
      color: black;

      .flip-card-front-title {
        @include flex-center;
        flex-direction: column;
        color: $primary-color;

        h2 {
          margin: 0;
          text-align: center;
          width: 50%;
          font-size: 20px;
        }
        h3 {
          margin: 0;
        }
      }

      .flip-card-front-image {
        filter: brightness(50%);
      }

      .flip-card-front-title,
      .flip-card-front-image {
        height: 50%;
        width: 100%;
      }
    }

    .flip-card-back {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      border: 3px solid $gray-shade-1;
      border-radius: 14px;
      background: $blue-gradient-3;
      color: white;
      transform: rotateY(180deg);

      .flip-card-back-text {
        overflow: auto;
      }

      .flipcard-button {
        margin-top: 1rem;

        .flipcard-link {
          color: $primary-color;
        }
      }

    }
  }

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media only screen and (max-width: 1920px) {
  .flip-card {
    margin: 3rem 6rem;
  }
}

@media only screen and (max-width: 800px) {
  .flip-card {
    margin: 2rem;
  }
}