@import "../../styles/var";
@import "../../styles/mixin";

.servicecard-container {
  @include flex-column;
  // width: fit-content;
  width: 420px;
  height: fit-content;
  border-radius: 8px;
  margin-bottom: 2rem;

  .servicecard-image-container {
    @include flex-justify-between;
    background-size: cover;
    padding-bottom: 10rem;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .servicecard-title-container,
    .servicecard-price-container {
      z-index: 2;
    }

    .servicecard-title-container {
      padding: 1rem 0 0 1rem;

      .servicecard-title-pill {
        border-radius: 3rem;
        border: 1px solid $white-shade-2;
        box-shadow: 0 0 4px $white-shade-2;
        background-color: $blue-shade-6;
        padding: 8px 1rem;
        width: fit-content;
        font-size: 17px;
        line-height: 1;
      }
    }

    .servicecard-price-container {
      @include flex-justify-end;
      padding-top: 1rem;
      padding-right: 1rem;

      .servicecard-price-tile {
        width: fit-content;
        height: fit-content;
        font-size: 30px;
        line-height: 1;
        padding: 8px;
      }
    }
  }

  .servicecard-button-container {
    @include flex-justify-between;
    padding: 10px 0;

    .servicecard-subtitle-tile {
      margin: 0 1rem;

      .servicecard-subtitle-text {
        padding: 4px;
        padding-left: 10px;
        font-size: 11px;
        line-height: 1.2;
      }
    }

    .servicecard-button {
      // width: 40%;

      .custom-button {
        height: 100%;
      }

      margin-right: 1rem;

      .servicecard-button-text {
        // padding: 1rem;
        font-size: 15px;
        text-wrap: nowrap;
      }
    }
  }
}

.gold-card-theme {

  .servicecard-image-container {

    .servicecard-title-container,
    .servicecard-price-container {
      z-index: 2;
    }

    .servicecard-title-container {

      .servicecard-title-pill {
        background: $gold-theme;
        color: $yellow-theme;
        border: 1px solid $yellow-theme;
        box-shadow: 0 0 4px $yellow-theme;
      }
    }

    .servicecard-price-container {
      .servicecard-price-tile {
        color: $yellow-theme;
        box-shadow: 0 0 4px $yellow-theme;
      }
    }
  }

  .servicecard-button-container {

    .servicecard-subtitle-tile {
      .servicecard-subtitle-text {
        color: $yellow-theme;
      }
    }

    .servicecard-button {
      .servicecard-button-text {
        color: yellow;
      }
    }
  }
}

.single-question-card {
  .servicecard-image-container {
    // background-image: url("../../assets/cardImages/single-question.png");
    background-size: cover;
  }
}

@media only screen and (max-width:500px) {
  .servicecard-container {

    .servicecard-image-container {

      // .servicecard-title-container,
      // .servicecard-price-container {

      // }

      .servicecard-title-container {
        .servicecard-title-pill {
          font-size: 15px;
        }
      }

      .servicecard-price-container {
        padding-right: 1rem;

        .servicecard-price-tile {
          padding: 8px !important;
          font-size: 25px;
        }
      }
    }

    .servicecard-button-container {

      .servicecard-subtitle-tile {

        .servicecard-subtitle-text {
          font-size: 10px;
          line-height: normal;
        }
      }

      .servicecard-button {
        .servicecard-button-text {}
      }
    }
  }

  // .gold-card-theme {

  //   .servicecard-image-container {

  //     .servicecard-title-container,
  //     .servicecard-price-container {
  //       z-index: 2;
  //     }

  //     .servicecard-title-container {
  //       .servicecard-title-pill {
  //       }
  //     }

  //     .servicecard-price-container {
  //       .servicecard-price-tile {
  //       }
  //     }
  //   }

  //   .servicecard-button-container {

  //     .servicecard-subtitle-tile {
  //       .servicecard-subtitle-text {
  //       }
  //     }

  //     .servicecard-button {

  //       .servicecard-button-text {
  //       }
  //     }
  //   }
  // }
}

// @media only screen and (max-width:450px) {
//   .servicecard-container {}
// }