@import "../../styles/var";
@import "../../styles/mixin";

.header-route-container {
  display: flex;
  align-items: center;
  position: relative;

  .header-route {
    margin-right: 20px;
    padding: 5px;
    text-decoration: none;
    color: $primary-color;
    border: 3px solid $white-shade-1;
    border-radius: 10px;

    &:hover {
      box-shadow: 0 0 10px $primary-color;
    }
  }
}

.sidebar-container {
  display: none;
}

@media only screen and (max-width: 700px) {

  .header-route-container {
    display: none;
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    position: absolute;
    z-index: 99;
    right: 0;
    transition: 3s ease;

    .sidebar {
      display: flex;
      flex-direction: column;
      background-color: $blue-shade-5;
      border-radius: 10px;
      box-shadow: 0 0 10px $primary-color;

      .header-route {
        padding-right: 0;
        margin: 1px;
        text-decoration: none;
        color: $primary-color;
        border: 1px solid transparent;
        padding: 10px;

        &:hover {
          background: $blue-gradient-6;
          color: $primary-color;
          border-radius: 10px;
          border: 1px solid $primary-color;
        }
      }
    }

  }
}