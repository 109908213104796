$default-color: #000;
$primary-color: #fff;

$white-shade-1: #FFF1F11C;
$white-shade-2: #DAD8C4;

//black color
$black-shade-1: #424242;
$black-shade-2: #212121;

//blue color
$blue-shade-1: #E0F4FF;
$blue-shade-2: #87C4FF;
$blue-shade-3: #39A7FF;
$blue-shade-4: #435585;
$blue-shade-5: #0C0B2D;
$blue-shade-6: #0F1C3E;

//black shades
$black-gradient-1: linear-gradient(to right, #434343 0%, black 100%);

//gray colors 
$gray-color: #bdaeae;
$gray-shade-1: #454444;

//yellow-theme
$yellow-theme: #FFE600;

//red theme
$red-color:#ff0000;

//blue gradients 
// $blue-gradient: linear-gradient(90deg, rgba(10,26,47,1) 0%, rgba(16,73,128,1) 53%, rgba(12,20,121,1) 100%);
// $blue-gradient-1: linear-gradient(90deg, rgba(76,131,200,1) 0%, rgba(0,117,230,1) 53%, rgba(106,108,133,1) 100%);

//blue gradients
$blue-gradient: linear-gradient(180deg, #14142B 0%, #06060E 100%), linear-gradient(0deg, rgba(255, 241, 241, 0.11), rgba(255, 241, 241, 0.11));
$blue-gradient-1: linear-gradient(180deg, #010007 0%, #06060E 100%), linear-gradient(0deg, #534F4F, #534F4F);
$blue-gradient-2: linear-gradient(180deg, #0E0E0E 0%, #0B0B29 100%), linear-gradient(0deg, #4B4747, #4B4747);
$blue-gradient-3: linear-gradient(180deg, #0A0D20 0%, #010310 100%), linear-gradient(0deg, #454444, #454444);
$blue-gradient-4: linear-gradient(180deg, #0E0E0E 0%, rgba(21, 21, 41, 0.505208) 49.48%, rgba(3, 1, 56, 0) 100%), linear-gradient(0deg, #4B4747, #4B4747);
$blue-gradient-5: linear-gradient(90.04deg, #0C0B2D 0.03%, #0B0A12 99.96%), linear-gradient(0deg, #4B4747, #4B4747);
$blue-gradient-6: linear-gradient(180deg, #6B7CA9 0%, rgba(93, 103, 131, 0) 100%), linear-gradient(0deg, #D7D7D7, #D7D7D7);
$blue-gradient-7: linear-gradient(180deg, rgba(14, 14, 14, 0.6) 0%, rgba(11, 11, 41, 0.6) 100%);
$blue-gradient-8: linear-gradient(90.04deg, #353549 0.03%, #0D0C31 34.01%, #0B0A12 99.96%);
$blue-gradient-9: linear-gradient(90.04deg, #3A371A 0.03%, #0F0F1D 37.51%, #0B0A12 99.96%);
$blue-gradient-10: linear-gradient(90.04deg, rgba(53, 53, 73, 0.5) 0.03%, rgba(13, 12, 49, 0.5) 34.01%, rgba(11, 10, 18, 0.5) 99.96%);
$blue-gradient-11: linear-gradient(180deg, #0F1C3E 0%, rgba(15, 28, 62, 0) 100%);

//gold theme
$gold-theme: linear-gradient(90.04deg, #3A371A 0.03%, #0F0F1D 37.51%, #0B0A12 99.96%),linear-gradient(0deg, #4B4747, #4B4747);
$gold-transparent: linear-gradient(90.04deg, rgba(150, 141, 61, 0.5) 0.03%, rgba(102, 95, 56, 0.5) 37.51%, rgba(11, 10, 18, 0.5) 99.96%);

