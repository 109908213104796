.mini-icon {
  width: 0.5rem;
}

.tiny-icon {
  width: 0.7rem;
}

.small-icon {
  width: 1rem;
}

.large-icon {
  width: 2rem;
}

.x-large-icon {
  width: 5rem;
}

.xxx-large-icon {
  width: 25rem;
}

.spin-logo {
  animation: spin 30s linear infinite;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
