@import "../../../styles/mixin";
@import "../../../styles/var";

.date-time-input-container {
  @include flex-column;
  padding: 1rem;

  .date-time-input-area {
    @include flex-column;
    border-radius: 10px;
    background: $blue-shade-5;
    box-shadow: 0 0 10px $primary-color;
    padding: 4px 1rem 8px;
    margin-bottom: 1rem;

    .date-time-input-label {
      font-size: 12px;
    }

    .date-time-input-box {
      display: flex;
    }

    .input-datebox,
    .input-timebox {
      outline: none;
      border: none;
      background: none;
      color: $primary-color;
      color-scheme: dark;
      width: 50%;
      padding: 0 1rem;
    }
  }
}

.input-transparent-blue-theme {
  .date-time-input-area {
    background: $blue-gradient-7 !important;
  }
}