@import "../../styles/var";

.addtocart-container {
  position: relative;

  .addtocart-total-item {
    position: absolute;
    text-shadow: 0 0 10px $primary-color;
    right: 2px;
    border-radius: 50%;
    background-color: cornflowerblue;
    padding: 3px;
    width: 25px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {

  .addtocart-container {

    .addtocart-total-item {
      top: 0;
      right: 0;
      font-size: 15px;
      width: 21px;
      padding: 0;
    }

    .add-to-cart-icon {
      width: 3rem !important;
    }
  }

}