@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-justify-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-justify-around {
  display: flex;
  justify-content: space-around;
}

@mixin flex-justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

@mixin flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

@mixin flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-align-start {
  display: flex;
  align-items: start;
}

@mixin flex-align-end {
  display: flex;
  align-items: end;
}