@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@import "../../styles/mixin";
@import "../../styles/var";

.about-container {
  @include flex-column;
  font-family: Poppins;

  .about-introduction-tile-container {
    margin: 3rem;
  }

  .about-introduction-container {
    @include flex-justify-between;
    align-items: center;

    .about-intro-main-text {
      @include flex-column;
      font-size: 25px;
      line-height: 1.2;
      font-weight: lighter;
      width: 70%;

      .about-who-am-i-title {
        font-size: 30px;
        text-shadow: 0 0 10px $primary-color;
      }
    }

    .about-astrologer-image {
      width: 18%;
    }
  }


  .about-astro-first-container {
    @include flex-justify-between;
    font-size: 25px;
    line-height: 1.3;

    .about-first-container-image-1 {
      height: 265px;
    }

    .about-first-container-image-2 {
      height: 348px;
    }

    .about-first-container-image-3 {
      height: 300px;
    }

    .about-first-container-image-4 {
      height: 230px;
    }

    .about-intro-first-details {
      @include flex-column;
      width: 60%;

      .about-astrologer-span-text-last {
        padding-top: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .about-container {
    .about-introduction-container {
      .about-astrologer-image {
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .about-container {
    .about-introduction-container {
      flex-direction: column;
      padding: 0;

      .about-intro-main-text {
        width: 100%;
        font-size: 20px;
      }

      .about-astrologer-image {
        width: 60%;
      }
    }

    .about-astro-first-container {
      flex-direction: column;

      .about-first-container-image-1,
      .about-first-container-image-2 {
        height: 80%;
      }

      .about-intro-first-details {
        width: 100%;
        text-align: left;
        padding-top: 0;
        padding-bottom: 2rem;
        font-size: 20px;
      }

      .about-intro-span-first,
      .about-intro-span-third {
        padding-bottom: 2rem !important;
      }

      .about-intro-span-second,
      .about-intro-span-fourth {
        padding-top: 2rem !important;
      }
    }

  }
}

@media only screen and (max-width: 600px) {
  .about-container {
    .about-introduction-tile-container {
      margin: 1.5rem;
    }

    .about-introduction-container {
      flex-direction: column;

      .about-astrologer-image {
        width: 80%;
      }
    }

    .about-astro-first-container {

      .about-first-container-image-1,
      .about-first-container-image-2,
      .about-first-container-image-3,
      .about-first-container-image-4 {
        height: 100% !important;
        width: 100% !important;
      }

      .about-first-container-image {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .about-container {
    .about-introduction-tile-container {
      height: 100% !important;

      .about-introduction-container {
        .about-intro-main-text {
          padding-top: 2rem;
          font-size: 15px;
        }
      }

      .about-astro-first-container {

        .about-intro-first-details {
          font-size: 15px;
          padding-bottom: 0;
        }
      }
    }
  }
}