@import "../../styles/var";
@import "../../styles/mixin";

.custom-button-container {
  .custom-button {
    transition: .3s ease;
  }
}

.pill-shape {
  .custom-button {
    border-radius: 2rem;
  }
}

.round-shape {
  border-radius: 10px;

  .custom-button {
    border-radius: 10px !important;
  }
}

.default-button {
  .custom-button {
    background-color: $default-color;
    color: $primary-color;
    box-shadow: 0 0 4px $primary-color;
  }
}

.blue-theme {
  .custom-button {
    background: $blue-gradient !important;
    color: $primary-color !important;
    box-shadow: 0 0 8px $primary-color !important;
    transition: .3s ease;

    &:hover {
      background: $blue-gradient-1;
      color: $primary-color;
      box-shadow: 0 0 14px $primary-color !important;
    }
  }
}

.blue-shade-1 {
  .custom-button {
    background: $blue-gradient-11 !important;
    color: $primary-color !important;
    box-shadow: 0 0 8px $primary-color !important;
    transition: .3s ease;

    &:hover {
      background: $blue-gradient-1;
      color: $primary-color;
      box-shadow: 0 0 14px $primary-color !important;
    }
  }
}

.gold-blue-theme {
  .custom-button {
    background: $blue-gradient-9 !important;
    color: $yellow-theme !important;
    box-shadow: 0 0 8px $yellow-theme;
    transition: .3s ease;
    margin-right: 0 !important;

    &:hover {
      color: $primary-color;
      box-shadow: 0 0 14px $yellow-theme !important;
    }
  }
}

.no-padding-button {
  .custom-button {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.half-padding {
  .custom-button {
    padding: 8px !important;
    margin: 0 !important;
  }
}

.no-background {
  .custom-button {
    background: none !important;
  }
}