@import "../../styles/mixin";
@import "../../styles/var";

.formik-input-errors {
  padding-left: 1rem;
  color: $red-color;
}

.input-container {
  @include flex-column;

  .input-area {
    @include flex-column;
    border-radius: 10px;
    background: $blue-shade-5;
    box-shadow: 0 0 10px $primary-color;
    padding: 4px 1rem 8px;
    margin: 1rem;
    cursor: text;

    .input-label {
      font-size: 12px;
    }

    .input-box,
    .input-textarea {
      outline: none;
      border: none;
      background: none;
      color: $primary-color;
    }

    .input-textarea {
      resize: none;
    }
  }
}

.paymentform-input {
  @include flex-column;

  .input-area {
    display: flex;
    border-radius: 10px;
    background: $blue-shade-5;
    box-shadow: 0 0 10px $primary-color;
    padding: 1rem 1rem 1rem;
    margin: 1rem;
    cursor: text;

    .input-label {
      font-size: 12px;
      padding-right: 1rem;
      font-size: 15px;
      line-height: normal;
      text-wrap: nowrap;
    }

    .input-box {
      width: 100%;
      color-scheme: dark;
    }

    .input-box,
    .input-textarea {
      outline: none;
      border: none;
      background: none;
      color: $primary-color;
    }

    .input-textarea {
      resize: none;
    }
  }
}

.input-transparent-blue-theme {
  .input-area {
    background: $blue-gradient-7 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .paymentform-input {
    @include flex-column;

    .input-area {
      @include flex-column;
      border-radius: 10px;
      background: $blue-shade-5;
      box-shadow: 0 0 10px $primary-color;
      padding: 4px 1rem 8px;
      margin: 1rem;
      cursor: text;

      .input-label {
        font-size: 12px;
      }

      .input-box {
        font-size: 16px;
        line-height: 1.5;
      }

      .input-box,
      .input-textarea {
        outline: none;
        border: none;
        background: none;
        color: $primary-color;
      }

      .input-textarea {
        resize: none;
      }
    }
  }
}

// @media only screen and (max-width: 600px) {
//   .input-container {

//     .input-area {
//       margin: 0;
//       margin-bottom: 1rem;
//     }
//   }

//   // .paymentform-input {
//   //  .input-area {
//   //     display: flex;
//   //     border-radius: 10px;
//   //     background: $blue-shade-5;
//   //     box-shadow: 0 0 10px $primary-color;
//   //     padding: 1rem 1rem 1rem;
//   //     margin: 1rem;
//   //     cursor: text;

//   //     .input-label {
//   //       font-size: 12px;
//   //       padding-right: 1rem;
//   //       font-size: 15px;
//   //       line-height: normal;
//   //       text-wrap: nowrap;
//   //     }

//   //     .input-box {
//   //       width: 100%;
//   //     }

//   //     .input-box,
//   //     .input-textarea {
//   //       outline: none;
//   //       border: none;
//   //       background: none;
//   //       color: $primary-color;
//   //     }

//   //     .input-textarea {
//   //       resize: none;
//   //     }
//   //   }
//   // }
// }