@import "../../styles/var";
@import "../../styles/mixin";

.tile-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 0 4px $primary-color;
  border-radius: 8px;
  background: $blue-gradient-2;
}

.blue-shade {
  background: $blue-gradient-2;
}

.blue-shade-1 {
  background: $blue-gradient-7;
}

.blue-shade-2 {
  background: $blue-gradient-8;
}

.transparent-blue-theme {
  background: $blue-gradient-7;
}

.transparent-gold-theme {
  background: $blue-gradient-7;
  box-shadow: 0 0 4px $yellow-theme;
}

.gold-blue-theme {
  background: $gold-theme;
  box-shadow: 0 0 4px $yellow-theme;
}

.gold-transparent-theme {
  background: $gold-transparent;
  box-shadow: 0 0 4px $yellow-theme;
}

.no-background-tile {
  background: none;
}

.no-padding {
  padding: 0 !important;
}