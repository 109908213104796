@import "../../../styles/mixin";
@import "../../../styles/var";

.services-payment-container {
  @include flex-column;
  padding: 2rem;

  .service-payment-title-container {
    padding-bottom: 1rem;
  }

  .services-payment-form-tile-container {
    background-image: url("./contactImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 2rem;

    .services-payment-title {
      text-shadow: 0 0 8px $primary-color;
      text-align: center;
      font-size: 40px;
    }

    .services-payment-subtitle {
      padding-left: 1rem;
      padding-top: 1rem;
      font-size: 20px;
      line-height: normal;
    }

    .services-payment-form-container {
      @include flex-column;

      .services-payment-single-question-data {
        @include flex-justify-between;
        padding: 1rem;
        font-size: 20px;

        .question-title,
        .question-price {
          padding-right: 1rem;
        }
      }

      .services-payment-checkout-container {
        @include flex-column;
        padding: 1rem;
        font-size: 20px;
        line-height: normal;

        .services-payment-checkout-list,
        .services-payment-checkout-total {
          @include flex-justify-between;
        }
      }

      .services-payment-form-details-container {
        @include flex-column;
        align-items: center;
      }

      // .services-payment-name-email-container {
      //   display: flex;

      //   .service-payment-name-input,
      //   .service-payment-email-input {
      //     width: 100%;
      //   }

      // }
      .service-payment-name-input,
      .service-payment-email-input,
      .service-payment-contact-input,
      .service-payment-comment-input,
      .service-payment-place-birth-input,
      .service-payment-date-birth-input,
      .service-payment-time-birth-input,
      .service-payment-booking-date-time-input {
        width: 500px;
      }

      .services-payment-form-button-container {
        @include flex-justify-evenly;
        padding-top: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .services-payment-container {

    .services-payment-form-tile-container {

      .services-payment-title {
        font-size: 30px;
      }

      .services-payment-subtitle {
        padding-left: 1rem;
        font-size: 15px;
        line-height: normal;
      }

      .services-payment-form-container {
        .services-payment-name-email-container {
          @include flex-column;

          .service-payment-name-input,
          .service-payment-email-input {
            width: unset;
          }

        }
        .service-payment-name-input,
        .service-payment-email-input,
        .service-payment-contact-input,
        .service-payment-comment-input,
        .service-payment-place-birth-input,
        .service-payment-date-birth-input,
        .service-payment-time-birth-input,
        .service-payment-booking-date-time-input {
          width: 100%;
        }

        .services-payment-form-button-container {}
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .services-payment-container {
    padding: 1rem;

    .services-payment-form-tile-container {
      .services-payment-form-container {
        .services-payment-single-question-data {
          @include flex-column;

          .services-payment-single-question-name-container {
            @include flex-column;

            .question-title {
              padding-bottom: 2px;
              line-height: normal;
            }
          }

          .services-payment-single-question-price-container {
            display: flex;
            padding-top: 1rem;

            .question-price {}
          }
        }
      }
    }
  }
}