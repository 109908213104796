@import "../../../styles/mixin";
@import "../../../styles/var";

.checkbox-container {
  @include flex-column;
  padding: 1rem;

  .checkbox-area {
    display: flex;

    .checkbox-label {
      padding-left: 1rem;
    }

    .checkbox-component {
      zoom: 1.4;
    }
  }
}


@media only screen and (max-width: 700px) {
  .checkbox-container {
  
    .checkbox-area {
  
      // .checkbox-label {
      // }
  
      .checkbox-component {
        zoom: 1.8;
      }
    }
  }
}