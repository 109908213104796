@import "../../styles/mixin";
@import "../../styles/var";

.modalcontainer {
  .modalcontainer-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}

.modal-blue-theme-container {
  .modalcontainer-header {
    background-color: black !important;
    color: $primary-color !important;

    .modal-close-button {
      .modal-close-icon {
        color: $primary-color;
      }
    }
  }
  .modal-content-container {
    background-color: black !important;
  }
}

@media only screen and (max-width: 767px) {
  .modalcontainer {
    display: flex;
    height: 60%;

    .modalcontainer-header {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }
    
    .modal-content-container {
      height: 100%;

      .modal-contentbox {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        overflow: auto;
        width: 100%;
        height: 100%;
      }
    }
  }
}