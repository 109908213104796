@import "../../styles/mixin";
@import "../../styles/var";

.footer-container {
  @include flex-center;
  padding: 2rem;

  .footer-area {
    @include flex-column;
    width: 100%;
    border-radius: 23px;
    border: 3px solid $white-shade-1;
    background: $blue-gradient;


    .footer-social-links {
      @include flex-column;
      align-items: center;
      padding: 1rem;

      .follow-social-title {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 1rem;
      }

      .footer-links {
        display: flex;
        padding: 1rem;

        .footer-social-link {
          padding-left: 1rem;
          padding-right: 1rem;
          cursor: pointer;

          .social-link-icon {
            color: $primary-color;
            font-size: 35px;
          }
        }
      }

    }

    .footer-section {
      @include flex-justify-between;
      padding: 1rem;
      padding-bottom: 0;

      .footer-policyMenus {
        display: flex;

        .policy-menu {
          text-decoration: none;
          color: $blue-shade-1;
          padding-right: 1rem;
          cursor: pointer;
        }
      }
    }

    .footer-developers-contact {
      @include flex-justify-between;
      padding: 1rem;
      padding-top: 4px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer-container {
    padding: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .footer-container {
    font-size: 12px;

    .footer-area {
      .footer-social-links {
        @include flex-justify-between;
        flex-direction: row;

        .follow-social-title {
          font-weight: lighter;
          padding: 0;
        }

        .footer-links {
          padding: 0;
          padding-top: 4px;

          .footer-social-link {
            padding: 0;
            padding-right: 6px;

            .social-link-icon {
              font-size: 25px;
            }
          }
        }
      }

      .footer-section {
        @include flex-column;

        .footer-policyMenus {
          @include flex-justify-between;

          .policy-menu {
            padding: 0;
          }
        }
      }
      .footer-developers-contact {
        flex-direction: column;
        padding: 1rem 1rem 8px;
      }
    }
  }
}