@import "../../styles/mixin";
@import "../../styles/var";

.contact-container {
  @include flex-column;
  font-weight: lighter;
  font-family: Poppins;

  .contact-main-image-container {
    padding: 2rem 8rem;
    background-image: url("./contactImg.png");
    background-size: cover;

    .contact-us-introduction-title {
      text-shadow: 0 0 20px $primary-color;
      text-align: center;
      font-size: 60px;
    }

    .contact-us-intro-text {
      font-size: 30px;
      line-height: 1.3;
    }

    .contact-connect-us-areabox {
      @include flex-justify-between;
      padding-top: 2rem;

      .contact-get-in-touch-container {
        width: 45%;

        .formik-error-message {
          padding-left: 1rem;
          line-height: normal;
          color: $red-color;
          text-shadow: 0 0 20px $primary-color;
          font-size: 16px;
        }

        .contact-get-in-touch-title {
          padding-left: 1rem;
          font-size: 40px;
        }

        .contact-form-button-container {
          @include flex-justify-between;
          align-items: center;
          padding-left: 1rem;
          padding-right: 2rem;
          padding-top: 1rem;

          .contact-form-submit-buttontext {
            font-size: 25px;
            line-height: 1.1;
          }
        }
      }

      .contacts-astrologer-information-container {
        justify-content: center;

        .contact-astrologer-card-row {
          @include flex-justify-around;

          .contact-astrologer-info-card {
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin: 2rem;
            width: 200px;
            height: 150px;

            .contact-tile-whatsapp,
            .contact-tile-email {

              font-size:40px;
              // margin-bottom: 2px;
              &::before {
                // font-size: 45px;
              }

              // margin-bottom: 2px;
            }

            .contact-astrologer-info-span-text {
              font-size: 20px;
              line-height: 1.3;
              color: $primary-color;
              padding-top: 1rem;
            }

            .contact-astrologer-info-whatsapp,
            .contact-astrologer-info-email {
              font-size: 20px;
              text-decoration: none;
              color: $primary-color;
              text-align: center;
            }
          }
        }
      }
    }

    .contact-us-faq-tiles-container {
      padding-top: 3rem;
      cursor: pointer;

      .faq-tiles-main-container {
        margin: 2rem 0;

        .faq-title-area {
          @include flex-justify-between;

          // align-items: center;

          .faq-title-question {
            @include flex-align-center;

            .faq-title-span-text {
              font-size: 30px;
              // font-family: Poppins;
              padding-left: 2rem;
            }
          }
        }

        .faq-tile-answer-text {
          padding-top: 2rem;
          font-size: 25px;
          line-height: 1.3;
        }
      }
    }

    .contact-us-last-tile-container {
      margin: 2rem 0;

      .contact-us-last-tile-text-1,
      .contact-us-last-tile-text-2 {
        font-size: 25px;
        line-height: 1.3;
      }

      .contact-us-last-tile-text-2 {
        padding-top: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .contact-container {
    .contact-main-image-container {


      .contact-us-introduction-title {}

      .contact-us-intro-text {}

      .contact-connect-us-areabox {
        flex-direction: column;
        align-items: center;

        .contact-get-in-touch-container {
          width: 50%;

          .contact-get-in-touch-title {}

          .contact-form-button-container {


            .contact-form-submit-buttontext {}
          }
        }

        .contacts-astrologer-information-container {
          margin-top: 2rem;
          width: 50%;

          .contact-astrologer-card-row {

            .contact-astrologer-info-card {


              .contact-astrologer-info-span-text {}
            }
          }
        }
      }

      .contact-us-faq-tiles-container {


        .faq-tiles-main-container {
          .faq-title-area {


            .faq-title-question {

              .faq-title-span-text {}
            }
          }

          .faq-tile-answer-text {}
        }
      }

      .contact-us-last-tile-container {

        .contact-us-last-tile-text-1,
        .contact-us-last-tile-text-2 {}

        .contact-us-last-tile-text-2 {}
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .contact-container {
    .contact-main-image-container {
      padding: 3rem;

      .contact-us-introduction-title {}

      .contact-us-intro-text {
        font-size: 18px;
      }

      .contact-connect-us-areabox {
        flex-direction: column;
        align-items: center;

        .contact-get-in-touch-container {
          width: 100%;

          .contact-get-in-touch-title {}

          .contact-form-button-container {


            .contact-form-submit-buttontext {}
          }
        }

        .contacts-astrologer-information-container {
          margin-top: 2rem;
          width: 100%;

          .contact-astrologer-card-row {

            .contact-astrologer-info-card {


              .contact-astrologer-info-span-text {
                font-size: 18px;
              }
            }
          }
        }
      }

      .contact-us-faq-tiles-container {


        .faq-tiles-main-container {
          .faq-title-area {


            .faq-title-question {

              .faq-title-span-text {
                font-size: 20px;
              }
            }
          }

          .faq-tile-answer-text {
            padding-top: 1rem;
            font-size: 18px;
          }
        }
      }

      .contact-us-last-tile-container {

        .contact-us-last-tile-text-1,
        .contact-us-last-tile-text-2 {
          font-size: 18px;
        }

        .contact-us-last-tile-text-2 {}
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-container {
    .contact-main-image-container {
      padding: 1.5rem;

      .contact-us-introduction-title {}

      .contact-us-intro-text {}

      .contact-connect-us-areabox {
        flex-direction: column;
        align-items: center;

        .contact-get-in-touch-container {

          .contact-get-in-touch-title {}

          .contact-form-button-container {


            .contact-form-submit-buttontext {}
          }
        }

        .contacts-astrologer-information-container {

          .contact-astrologer-card-row {
            @include flex-justify-between;

            .contact-astrologer-info-card {
              margin: 0;
              width: 46%;
              height: 120px;

              .contact-astrologer-info-span-text {
                font-size: 14px;
                text-align: center;
              }
            }
          }

          .contact-astrologer-card-row-1 {
            padding-top: 1rem;
          }
        }
      }

      .contact-us-faq-tiles-container {


        .faq-tiles-main-container {
          .faq-title-area {

            .faq-title-question-icon {
              width: 30px !important;
            }

            .faq-title-question {

              .faq-title-span-text {
                padding-left: 8px;
                font-size: 18px;
                font-family: Poppins;
              }
            }
          }

          .faq-tile-answer-text {}
        }
      }

      .contact-us-last-tile-container {

        .contact-us-last-tile-text-1,
        .contact-us-last-tile-text-2 {}

        .contact-us-last-tile-text-2 {}
      }
    }
  }
}