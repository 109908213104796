@import "../../styles/var";
@import "../../styles/mixin";

.homepage-container {
  @include flex-column;
  width: 100%;
  height: 100%;
  font-family: Poppins;


  .homepage-introduction-container {
    @include flex-center;
    flex-direction: column;
    padding-top: 2rem;

    .homepage-logo-container {
      position: relative;

      .astro-intro-applogo {
        position: absolute;
        top: 170px;
        left: 124px;
      }
    }

    .homepage-title-container {
      text-align: center;
      padding-top: 2rem;

      h1 {
        margin: 0;
        font-size: 40px;
      }
    }

    .homepage-subtitle-container {
      @include flex-column;
      text-align: center;
      font-size: 25px;
      padding-top: 2rem;

      span {
        line-height: 1.3;
      }
    }
  }

  .homepage-intro-second-container {
    @include flex-justify-around;
    align-items: center;
    padding: 1rem;
    padding-top: 3rem;

    .homepage-intro-tile {
      font-size: 20px;
      width: 50%;
      height: fit-content;

      .homepage-intro-about-text {
        padding: 2rem;
        line-height: 1.3;
      }
    }

    .homepage-about-cardstar {
      height: 80%;
      box-shadow: 0 0 15px $primary-color;
      border-radius: 50%;
    }

  }

  .homepage-intro-third-container {
    @include flex-justify-end;
    background-image: url("../../assets/astro_cards.png");
    background-size: cover;
    padding: 4rem;
    margin-top: 2rem;

    .astro-dedicate-tile {
      padding: 2rem;
      width: 30%;
      font-size: 20px;
      line-height: 1.3
    }
  }

  .homepage-intro-fourth-container {
    @include flex-justify-around;
    align-items: center;
    padding: 2rem;

    .reveal-future-tile {
      width: 50%;
      font-size: 20px;
      line-height: 1.4;
    }

    .homepage-tab-astro {
      box-shadow: 0 0 15px $primary-color;
      border-radius: 2rem;
    }
  }

  .skills-container {
    @include flex-column;
    padding-top: 2rem;

    .skills-cardcontainer {
      background-image: url("../../assets/homepage-skills.png");
      background-size: cover;
      padding-top: 4rem;

      .skills-cardcontainer-title {
        @include flex-center;
        flex-direction: column;

        h2 {
          margin: 0;
        }
      }

      .skills-cards-area {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .about-astrology-tile-container {
    @include flex-column;
    padding-top: 3rem;

    .about-astrology-home-title {
      text-align: center;
      font-size: 40px;
    }

    .about-astro-brief-tile-container {
      @include flex-justify-between;
      flex-direction: row;
      align-items: center;
      margin: 1rem 3rem 3rem;

      .about-astrologist-image {
        height: 250px;
      }

      .astro-brief-areabox {
        @include flex-column;
        justify-content: center;
        width: 70%;
        height: fit-content;

        .about-astro-brief-heading-name {
          text-align: center;
          text-shadow: 0 0 15px $primary-color;
          padding-bottom: 1rem;
          font-size: 40px;
        }
        
        h3 {
          text-align: center;
        }

        h2,
        h3 {
          font-size: 30px;
          margin: 0;
        }

        .astro-brief-span-text {
          font-size: 25px;
          line-height: normal;
        }

        .homepage-about-readmore-button-container {
          @include flex-justify-end;
          padding: 1rem;

          .homepage-about-readmore-button {
            .homepage-about-readmore-button-text {
              font-size: 20px;
              padding: 1rem;
            }
          }
        }

      }
    }
  }

}


@media only screen and (max-width: 1200px) {
  .homepage-container {
    .about-astrology-tile-container {
      .about-astrology-home-title {
        text-align: center
      }

      .about-astro-brief-tile-container {

        .about-astrologist-image {
          width: 30%;
        }

        .astro-brief-areabox {
          width: 60%;

          h3 {
            font-size: 25px;
          }

          span {
            font-size: 20px;
          }

          .homepage-about-readmore-button-container {
            padding-top: 6rem;

            .homepage-about-readmore-button {
              .homepage-about-readmore-button-text {}
            }
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .homepage-container {

    .homepage-introduction-container {
      .homepage-subtitle-container {
        padding: 2rem;
      }
    }

    .homepage-intro-second-container {
      flex-direction: column;

      .homepage-intro-tile {
        width: 100%;
        margin-bottom: 2rem;
      }

      .homepage-about-cardstar {
        height: 40%;
      }
    }

    .homepage-intro-third-container {
      .astro-dedicate-tile {
        padding: 2rem;
        width: 50%;
      }
    }

    .about-astrology-tile-container {
      .about-astro-brief-tile-container {
        .about-astrologist-image {
          width: 40%;
        }

        .astro-brief-areabox {
          width: 55%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .homepage-container {

    .homepage-introduction-container {

      .astro-intro-logo {
        padding: 2rem;
      }

      .homepage-title-container {}

      .homepage-subtitle-container {
        font-size: 20px;
      }

    }

    .homepage-intro-second-container {
      flex-direction: column;

      .homepage-intro-tile {
        width: 100%;
        margin-bottom: 2rem;
        font-size: 15px;
      }

      .homepage-about-cardstar {
        height: 40%;
      }
    }

    .homepage-intro-third-container {
      padding: 2rem;

      .astro-dedicate-tile {
        padding: 2rem;
        width: 100%;
      }
    }

    .skills-container {

      .skills-cardcontainer {

        .skills-cardcontainer-title {
          @include flex-center;
          flex-direction: column;
          padding: 0 3rem;

          h2 {
            margin: 0;
            text-align: center;
          }
        }

        .skills-cards-area {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding-top: 4rem;
        }
      }
    }

    .about-astrology-tile-container {
      .about-astrology-home-title {
        text-align: center;
        padding: 2rem;
        font-size: 35px;
      }

      .about-astro-brief-tile-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .about-astrologist-image {
          height: 100%;
        }

        .astro-brief-areabox {
          width: 100%;
          padding: 0;

          h3 {
            padding-top: 1rem;
            font-size: 25px;
            text-align: center;
          }

          .astro-brief-span-text {
            font-size: 20px !important;
            text-align: center;
          }

          .homepage-about-readmore-button-container {
            padding-top: 2rem;
            padding-bottom: 2rem;
            justify-content: center;

            .homepage-about-readmore-button {
              .homepage-about-readmore-button-text {}
            }
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .homepage-container {

    .homepage-introduction-container {

      .homepage-title-container {
        h1 {
          font-size: 30px;
        }
      }

      .homepage-subtitle-container {
        font-size: 15px;

        span {
          padding-top: 10px;
        }
      }

    }

    .homepage-intro-second-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: fit-content !important;

      .homepage-intro-tile {
        width: 100%;
        margin-bottom: 2rem;
        font-size: 15px;
      }

      .homepage-about-cardstar {
        height: 300px;
      }
    }

    .homepage-intro-fourth-container {

      .reveal-future-tile {
        width: 50%;
      }

      .homepage-tab-astro {
        height: 50%;
      }
    }
  }
}



@media only screen and (max-width: 600px) {
  .homepage-container {
    .homepage-intro-fourth-container {
      flex-direction: column;

      .reveal-future-tile {
        width: 100%;
        margin-bottom: 2rem;
      }

      .homepage-tab-astro {
        height: 50%;
      }
    }

    .about-astrology-tile-container {
      .about-astro-brief-tile-container {
        .about-astrologist-image {
          width: 75%;
        }
      }
    }
  }
}


@media only screen and (max-width: 450px) {
  .homepage-container {
    // span {
    //   font-size: 18px;
    // }

    .homepage-introduction-container {
      .homepage-logo-container {
        .astro-intro-logo {
          width: 27rem !important;
        }

        .astro-intro-applogo {
          width: 10rem !important;
          top: 160px;
        }
      }

      .homepage-title-container {
        .homepage-heading-title {
          font-size: 26px;
        }
      }

      .homepage-subtitle-container {
        padding: 1rem;
        span {
          padding-top: 10px;
        }
      }

    }

    .homepage-intro-second-container {
      display: flex;
      flex-direction: column;

      .homepage-intro-tile {
        width: 100%;
        font-size: 15px;

        .homepage-intro-about-text {
          padding: 0;
          padding-bottom: 1rem;
        }
      }
    }

    .homepage-intro-third-container {
      .astro-dedicate-tile {
        font-size: 15px;
      }
    }

    .homepage-intro-fourth-container {
      .reveal-future-tile {
        font-size: 15px;
      }

      .homepage-tab-astro {
        height: 30%;
      }
    }

    .about-astrology-tile-container {
      .about-astrology-home-title {
        padding: 1rem;
      }

      .about-astro-brief-tile-container {
        margin: 1rem 1rem 1rem;
      }
    }
  }
}