@import "../../../styles/mixin";
@import "../../../styles/var";

.services-description-title-container {
  padding: 1rem;
}

.service-description-container {
  @include flex-column;
  margin: 2rem;

  .services-description-title {
    font-size: 40px;
    text-shadow: 0 0 8px $primary-color;
    padding-left: 4px;
  }

  .service-description-data-container {
    @include flex-justify-between;

    .services-description-about-tile {
      margin-right: 2rem;
      width: 60%;
      font-size: 20px;

      .services-description-poet-container {
        text-align: center
      }
    }

    .service-description-image-container {
      @include flex-justify-end;
      background-size: cover;
      height: 550px;
      width: 40%;
      border-radius: 1rem;
      box-shadow: 0 0 8px $primary-color;

      .services-description-price-tag {
        width: fit-content;
        height: fit-content;
        margin: 1rem 1rem 0;
        font-size: 50px;
        line-height: 1;
      }
    }
  }
  .service-description-feature-container {
    padding-top: 2rem;
    font-size: 20px;
    .services-container-feature-title {
      padding-left: 1rem;
    }
    .services-container-feature-list-box {
      display: flex;
      flex-wrap: wrap;

      .servicecard-list-data {
        @include flex-align-center;
        padding: 1rem;
        .servicecard-list-data-text {
          padding-left: 1rem;
        }
      }
    }
  }

  .service-description-single-question-choose-container {
    @include flex-column;
    padding-top: 2rem;
    font-size: 25px;
    line-height: 1.5;

    .services-description-single-question-tile-open {
      .services-description-single-question-category-name-container {
        padding-bottom: 2rem;
      }
    }

    .services-description-single-question-tile-container {
      margin-bottom: 2rem;
      
      .services-description-single-question-category-name-container {
        @include flex-justify-between;
        align-items: center;
        cursor: pointer;

        .services-description-single-question-category-namebox {
          @include flex-align-center;

          .services-description-single-question-category {
            padding-left: 1rem;
          }
        }
      }

      .single-question-radio-button {
        width: 20px;
        height: 20px;
      }

      .single-question-option-text {
        border-bottom: 1px solid $primary-color;
        padding-top: 1rem;
        margin-left: 1rem;
      }
    }
  }

  .service-description-book-now-button-container {
    @include flex-justify-center;
    padding-top: 2rem;
  }

}

.astro-numero-desc {
  .services-description-title {
    color: $yellow-theme;
    text-shadow: 0 0 8px $yellow-theme;
  }
  .service-description-data-container {

    .service-description-image-container {
      box-shadow: 0 0 8px $yellow-theme;

      .services-description-price-tag {
        color: $yellow-theme;
      }
    }
  }
}



@media screen and (max-width: 1000px) {
  .service-description-container {
    @include flex-column;

    .services-description-title {
      padding-bottom: 1rem;
      margin:0;
    }

    .service-description-data-container {
      flex-direction: column-reverse;

      .services-description-about-tile {
        width: 100%;
        margin-top: 2rem;
      }

      .service-description-image-container {
        width: 100%;
        height: 500px;

        .services-description-price-tag {
          width: fit-content;
          height: fit-content;
          margin: 1rem 1rem 0;
          font-size: 50px;
          line-height: 1;
        }
      }
    }

  }
}

@media screen and (max-width: 600px) {
  .service-description-container {
    margin: 1rem;

    .services-description-title {
      font-size: 30px;
    }

    .service-description-data-container {
      flex-direction: column-reverse;

      .services-description-about-tile {
        width: 100%;
        margin-top: 2rem;
        font-size: 14px;
      }

      .service-description-image-container {
        width: 100%;
        height: 270px;

        .services-description-price-tag {
          width: fit-content;
          height: fit-content;
          margin: 1rem 1rem 0;
          font-size: 30px;
          line-height: 0.6;
        }
      }
    }

    .service-description-single-question-choose-container {
      @include flex-column;
      padding-top: 2rem;
      font-size: 18px;
      line-height: 1.5;
  
      // .services-description-single-question-tile-open {
      //   .services-description-single-question-category-name-container {
      //   }
      // }
  
      .services-description-single-question-tile-container {
        
        .services-description-single-question-category-name-container {
          .single-question-tile-icon {
            width: 1.5rem !important;
            height: 1.5rem !important;
          }
  
          // .services-description-single-question-category-namebox {
  
          //   .services-description-single-question-category {
          //   }
          // }
        }
      }
    }

  }
}