@import "../../styles/var";
@import "../../styles/mixin";

.home-container {
  @include flex-column;
  width: 100%;
  height: 100%;
  transition: .3s ease;
  overflow: hidden;
  background: $blue-gradient-2;
  background-image: url("../../assets/astrogif.gif");
  background-size: cover;


  .outlet-container {
    @include flex-column;
    width: 100%;
    height: 100%;
    transition: .3s ease;
    overflow-y: auto;
    overflow-x: hidden;
    color: $primary-color;


    &::-webkit-scrollbar {
      width: 5px;
      background: $black-gradient-1
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray;
    }
  }
}