@import "../../styles/mixin";
@import "../../styles/var";

.service-cart-container {
  @include flex-column;
  justify-content: center;

  .service-cart-title-container {
    @include flex-justify-between;
    border-bottom: 1px solid;
    padding-bottom: 8px;

    .service-cart-title-text,
    .service-cart-title-action {
      font-size: 15px;
      font-weight: bolder;
      // line-height: normal;
    }

    .service-cart-title-text {
      width: 20%;
    }
  }

  .service-cart-list {
    font-size: 15px;
    line-height: normal;
    padding: 1rem 0;

    .service-list-name,
    .service-list-price {
      width: 20%;
    }
  }

  .service-cart-button-container {
    @include flex-justify-center;
  }

  .service-card-subtotal-container {
    border-top: 1px solid;
    padding-bottom: 1rem;
    .service-cart-total-text {
      font-size: 20px;
      font-weight: bolder;
    }
  }

  .service-card-subtotal-container,
  .service-cart-list {
    @include flex-justify-between;
  }
}

.services-container {
  @include flex-column;

  .services-header-container {
    @include flex-align-center;
    padding-right: 2rem;

    .services-page-header-title {
      @include flex-justify-center;
      font-size: 50px;
      width: 90%;
      text-shadow: 0 0 20px $primary-color;
      margin: 0;
      // padding-left: 12rem;
    }
  }

  .services-page-header-subtitle {
    font-size: 40px;
    padding-left: 2rem;
  }

  .service-book-one-consultation-header {
    text-align: center;
    font-size: 30px;
    // padding-left: 2rem;
  }

  .services-tiles-container {
    @include flex-justify-between;
    flex-wrap: wrap;
    padding: 2rem;
    height: 100%;
  }
}

.is-services-card-page {
  .services-page-header-title {
    width: 100% !important;
  }
}

@media only screen and (max-width:915px) {
  .services-container {
    height: fit-content;

    .services-header-container {
      @include flex-column;
      flex-direction: column-reverse;
      align-items: end;
      .services-page-header-title {
        font-size: 40px;
        text-align: center;
      }
    }

    .services-page-header-subtitle {
      font-size: 35px;
    }

    .services-tiles-container {
      justify-content: center;
      padding-bottom: 0;
    }
  }
}

@media only screen and (max-width:600px) {
  .services-container {

    .services-header-container {
      padding-right: 1rem;

      .services-back-button {
        width: 3rem !important;
      }

      .services-page-header-title {
        width: unset;
        font-size: 35px;
      }

      .services-cart-button-container {
        // width: 100%;
        align-items: end;
      }
    }

    .services-page-header-subtitle {
      font-size: 28px;
      padding-left: 0;
      text-align: center;
    }

    .service-book-one-consultation-header {
      font-size: 25px;
    }

    .services-tiles-container {
      padding: 1.5rem;
      // align-items: center;

    }
  }
}