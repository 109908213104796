@import "../../styles/var";
@import "../../styles/mixin";

.header-container {
  @include flex-center;
  background: transparent;

  .header-area {
    @include flex-justify-between;
    align-items: center;
    width: 98%;
    height: fit-content;
    border-radius: 18px;
    background: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;

    .header-title {
      @include flex-align-center;
      padding: 1rem;
      color: $primary-color;
    }

    .menu-button {
      display: none;
    }
  }
}

@media only screen and (max-width: 700px) {
  .header-container {
    .menu-icon {
      margin: 0 !important;
      color: $primary-color;
      transition: 0.5s ease !important;
      font-size: 30px;

      &:hover {
        text-shadow: 0 0 12px $primary-color;
      }

      // color: $primary-color !important;
    }

    .menu-button {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .header-container {
    align-items: center;

    .header-area {
      .header-app-logo {
        width: 8rem !important; 
      }
    }

    .menu-route-container {
      display: none;
    }

    .menu-button {
      padding: 0 !important;
    }
  }
}